import Navigation from "./Navigation";
import './App.scss';
import "../src/Screens/Login/Login.scss"
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <Navigation />
    </>
  );
}

export default App;
