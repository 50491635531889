import React, { useEffect, useState } from 'react';
import '../../assets/css/style.css'
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import plusicon from '../../assets/images/plus-circle.svg';
import hdfcimg from '../../assets/images/hdfc.svg';
import sbiimg from '../../assets/images/sbi.svg';
import checkicon from '../../assets/images/check-icon.png';
import Dropdown from 'react-bootstrap/Dropdown';
import WebService from '../../utility/webService';
import HelperService from '../../utility/HelperService';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import DefaultImage from '../../assets/images/default.svg';

const ManageAccounts = () => {
    const [accounts, setAccounts] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getAccounts()
    }, [])

    const getAccounts = () => {
        WebService.getAPI({
            action: `/aaclient/accounts/LINKED`,
            body: null
        })
            .then((res: any) => {
                setAccounts(res.accountVOList)
            })
            .catch((e) => {

            })
    }

    const onDelete = (value: any) => {
        setLoading(true)
        WebService.deleteAPI({
            action: `/aaclient/account/link/${value}`,
            body: null
        })
            .then((res: any) => {
                setLoading(false)
                toast.success(res.Message)
                getAccounts()
            })
            .catch((e) => {
                setLoading(false)
                toast.error(e.response.data.errorMsg)
            })
    }
    return (
        <>
            <Loader show={isLoading} />
            <div className='mt100 om-manageac-wrapper'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <span>Accounts</span>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-8 mx-auto my-auto pt-4">
                            <h4 className="d-none d-sm-block">Accounts</h4>
                            {accounts?.length == 0 && <div className="d-none d-sm-block disc-acct">Linked Accounts </div>}
                            {accounts?.length == 0 && <h5 className="d-none d-sm-block text-center py-3">No Linked Accounts!</h5>}
                            {accounts.length > 0 &&
                                <Accordion defaultActiveKey="0">
                                    {
                                        accounts?.map((res, index: any) => {
                                            return (
                                                <Accordion.Item eventKey={index}>
                                                    <Accordion.Header><img src={res.fipLogo ? res.fipLogo : DefaultImage} alt="" />&nbsp; {res.fipName}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {res.accounts.length > 0 && res.accounts.map((val: any) => {
                                                            return (
                                                                <div className="row mb-3 d-flex align-items-center" key={index}>
                                                                    <div className="col-6">
                                                                        <p className="osm-ac-no"><img src={checkicon} className="osm-check-icon" alt="" />{val.maskedAccNumber}</p>
                                                                    </div>
                                                                    <div className="col-6 text-right">
                                                                        <div className="osm-saving-wrap">
                                                                            <div>
                                                                                <div className='add-account-type'>{val.fiType && HelperService.getTitleCase(val.fiType)}</div>
                                                                                <Link to="#" className="tc">{val.accType && HelperService.getTitleCase(val.accType)}</Link>
                                                                            </div>
                                                                            <div className="osm-ac-edit dropdown">
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => onDelete(val.linkRefNumber)}>Delete</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        })
                                    }
                                </Accordion>
                            }
                            <div className="add-accounts text-center my-5"><Link to="/search-bank"><img src={plusicon} alt="" /> Add Accounts</Link></div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}

export default ManageAccounts;