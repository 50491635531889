import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import nextarrow from '../../assets/images/next-arrow.svg';
import WebService, { BASE_URL } from '../../utility/webService';
import { useNavigate } from "react-router-dom";

const ManageConsent = () => {
    const [consentData, setConsentData] = useState<any>()
    let navigate = useNavigate();

    useEffect(() => {
        getConsentData()
    }, [])

    const getConsentData = () => {
        WebService.getAPI({
            action: `/aaclient/Consent`,
            body: null
        })
            .then((res: any) => {
                setConsentData(res.list)
            })
            .catch((e) => {

            })
    }

    return (
        <>
            <div className="mt100 oms-manconsent-wrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <span>Manage Consents</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <h4 className="d-none d-sm-block mt-4">Manage Consents</h4>
                            <div className="white-row pointer" onClick={() => consentData?.PENDING != 0 && navigate("/consents-list", { state: { type: 'PENDING' } })}>
                                Pending <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.PENDING}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                            </div>
                            <div className="white-row pointer" onClick={() => consentData?.ACTIVE != 0 && navigate("/consents-list", { state: { type: 'ACTIVE' } })}>
                                Active <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.ACTIVE}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                            </div>
                            <div className="white-row pointer" onClick={() => consentData?.REVOKED != 0 && navigate("/consents-list", { state: { type: 'REVOKED' } })}>
                                Revoked <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.REVOKED}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                            </div>
                            <div className="white-row pointer" onClick={() => consentData?.EXPIRED != 0 && navigate("/consents-list", { state: { type: 'EXPIRED' } })}>
                                Expired <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.EXPIRED}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                            </div>
                            <div className="white-row pointer" onClick={() => consentData?.PAUSED != 0 && navigate("/consents-list", { state: { type: 'PAUSED' } })}>
                                Paused <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.PAUSED}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageConsent;