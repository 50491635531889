import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import nextarrwoimg from '../../assets/images/next-arrow.svg'
import bajajimg from '../../assets/images/bajaj.svg'
import sbiimg from '../../assets/images/sbi.svg'
import WebService from '../../utility/webService';
import nextarrow from '../../assets/images/next-arrow.svg';

const Home = () => {
    const [consentData, setConsentData] = useState<any>()
    let navigate = useNavigate();

    useEffect(() => {
        getConsentData()
    }, [])

    const getConsentData = () => {
        WebService.getAPI({
            action: `/aaclient/Consent`,
            body: null
        })
            .then((res: any) => {
                setConsentData(res.list)
            })
            .catch((e) => {

            })
    }
    return (
        <>
            <section className="mt100">
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            {/* <h4 className="d-none d-sm-block mt-4">No Consent Request</h4> */}
                            <div className="gradient-box mt-4">
                                Players regulated by RBI, SEBI, IRDAI, PFRDA can participate in AA ecosystem.<br />
                                <a onClick={() => navigate('/search-bank')} className="find-your-bank">Discovered Accounts</a>
                            </div>
                            <div className="">
                                <h4 className=" mt-4">Manage Consents</h4>
                                <div className="white-row pointer" onClick={() => consentData?.PENDING != 0 && navigate("/consents-list", { state: { type: 'PENDING' } })}>
                                    Pending <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.PENDING}</span> <Link to="/pending"><img src={nextarrow} alt="" /></Link></span>
                                </div>
                                <div className="white-row pointer" onClick={() => consentData?.ACTIVE != 0 && navigate("/consents-list", { state: { type: 'ACTIVE' } })}>
                                    Active <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.ACTIVE}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                                </div>
                                <div className="white-row pointer" onClick={() => consentData?.REVOKED != 0 && navigate("/consents-list", { state: { type: 'REVOKED' } })}>
                                    Revoked <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.REVOKED}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                                </div>
                                <div className="white-row pointer" onClick={() => consentData?.EXPIRED != 0 && navigate("/consents-list", { state: { type: 'EXPIRED' } })}>
                                    Expired <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.EXPIRED}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                                </div>
                                <div className="white-row pointer" onClick={() => consentData?.PAUSED != 0 && navigate("/consents-list", { state: { type: 'PAUSED' } })}>
                                    Paused <span className="pull-right mn-con-icon"><span className="red-circle">{consentData?.PAUSED}</span> <Link to="#"><img src={nextarrow} alt="" /></Link></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;