import logo from '../../assets/images/logo.svg';
import OTPInput from "react-otp-input";
import { useState, useEffect, useRef } from 'react';
import WebService from '../../utility/webService';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import backarrowimg from '../../assets/images/back-arrow.svg'

const AccountsOtpScreen = () => {
    const [pin, setPin] = useState<any>("");
    const [counter, setCounter] = useState(59);
    let location = useLocation();
    const [refNumber, setRefNumber] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    let history = useNavigate();
    const otpRef = useRef<any>();

    useEffect(() => {
        if (location.state != null) {
            setRefNumber(location.state.refNumber)
        }
    }, [location]);

    setTimeout(() => {
        var count: any = counter - 1;
        if (count >= 0) {
            count = count > 9 ? count : '0' + '' + count;
        }
        if (count >= 0) {
            setCounter(count)
        } else if (count == 0) {
            return
        }
    }, 1000);

    const handleChange = (value: any) => {
        setPin(value)
    }

    const onVerify = () => {
        if (pin.length < 6) {
            toast.error('Please enter correct otp')
        } else {
            setLoading(true)
            WebService.getAPI({
                action: `/aaclient/Accounts/link/${refNumber}/${pin}`,
                body: null
            })
                .then((res: any) => {
                    setLoading(false)
                    toast.success(res.message)
                    history('/home')
                })
                .catch((e) => {
                    setPin('')
                    otpRef.current.focusInput(0)
                    setLoading(false)
                    toast.error(e.response.data.errorMsg)
                })
        }
    }

    const onResend = () => {

    }

    return (
        <>
            <section>
                <div className="container">
                    <div className="row align-items-cente">
                        <div className="col-lg-12 col-sm-12 mb-4">
                            <header className="d-none d-sm-block">
                                <nav className="navbar navbar-expand-md navbar-dark fixed-top">
                                    <div className="container">
                                        <a className="navbar-brand">
                                            <img src={logo} alt="" width="70" height="70" />
                                        </a>
                                    </div>
                                </nav>
                            </header>
                            <div className="white-bg  d-block d-sm-none">
                                <a onClick={() => window.history.back()} className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Verify OTP</span>
                            </div>
                        </div>
                        <div className="vertical-center">
                            <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                                <div className="tag-line pb-2">Authenticate your account</div>
                                <div className="sm-title pb-3">Enter the OTP sent by {location.state && location.state.name} to the
                                    registered mobile number</div>
                                <form method="get" className="digit-group text-center" data-group-name="digits" data-autosubmit="false">
                                    <div className="otp">
                                        <OTPInput
                                            ref={otpRef}
                                            onChange={handleChange}
                                            value={pin}
                                            inputStyle="otpInputStyle"
                                            className='otpbox'
                                            isInputSecure={true}
                                            numInputs={8}
                                            separator={<span></span>}
                                        />
                                    </div>
                                    {/* <div className="resend mt-3">Resend Activation Code <span>00:{counter}</span></div> */}
                                    {/* <div className="text-center"><a onClick={() => counter == 0 && onResend()} className={counter != 0 ? 'disabled text-white' : 'tc pointer'}>Resend Activation Code</a></div> */}
                                    <div className="form-group mt-5  btn-fixed">
                                        <button type="button" name="" value="Verify" className="btns" onClick={() => onVerify()} disabled={isLoading}>
                                            {isLoading && <div className='om-btn-loader-m'></div>}Verify</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AccountsOtpScreen;