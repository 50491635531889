import successIcon from '../../assets/images/success-icon.svg';
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { USER_LOGIN_SUCCESS } from '../../action/CommonActions';

const Success = () => {
    let history = useNavigate();
    let location = useLocation();
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        if (location.state == null) {
            history('/login')
        }
    }, [])

    const onNext = () => {
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: { access_token: location.state.token },
        });
        sessionStorage.setItem("token", location.state.token);
        history("/search-bank", { state: { token: location.state.token } });
    }

    return (
        <>
            <section>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-lg-5 col-sm-5 mx-auto">
                            <div className="blue-bg vertical-center">
                                <div className="pt-5">
                                    <h1 className="success">Congratulations!</h1>
                                    <div className="tag-line pb-2">Your OMS ID is <b>{location.state && location.state.id}</b></div>
                                    <div className="text-center my-5"><img src={successIcon} alt="OMS AA" width="190" height="184" />
                                    </div>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <div className="text-center mt-5 btn-fixed">
                                <button type="button" name="" value="Next" className="btns" onClick={() => onNext()}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Success;