import { useEffect, useState } from "react";
import './Header.scss';
import logo from '../../assets/images/logo.svg'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
    setDataInRedux,
    USER_LOGOUT
} from "../../action/CommonActions";
import WebService from '../../utility/webService';


const Header = () => {
    const [show, setShow] = useState(false);
    let navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const [name, setName] = useState('')

    useEffect(() => {
        getUserDetail()
    }, [])

    const getUserDetail = () => {
        WebService.getAPI({
            action: `/aaclient/customer`,
            body: null
        })
            .then((res: any) => {
                setName(res.name)
            })
            .catch((e) => {

            })
    }

    const handleClose = () => setShow(false);

    const onLogout = () => {
        handleClose()
        sessionStorage.clear()
        localStorage.clear();
        dispatch(setDataInRedux({ type: USER_LOGOUT, value: '' }));
        navigate('/login')
    }


    return (
        <>
            <div className='osm-menu-bar'>
                <Navbar collapseOnSelect expand="lg" className='fixed-top bg-light' >
                    <Container>
                        <Navbar.Brand onClick={() => navigate('/home')}> <img src={logo} alt="" width="70" height="70" /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/home">Home</Nav.Link>
                                <Nav.Link href="/manage-accounts">Manage Accounts</Nav.Link>
                                <Nav.Link href="/manage-consent">Manage Consent</Nav.Link>
                                {/* <NavDropdown title={<div className='osm-navlink' ><i className="fa fa-bell osm-bell" aria-hidden="true">
                                    <span className="osm-notification">1</span></i> </div>} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">
                                        Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                        Separated link
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                                <NavDropdown title={<div className='om-nav-profile'>
                                    {name && name.slice(0, 2).toUpperCase()}
                                    {/* <img src={profile} alt="" width="40" height="40" /> */}
                                </div>} id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/edit-profile">Edit Profile</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="/change-pin">
                                        Change PIN
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => navigate('/help-and-feedback')}>Help and Feedback</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => window.open('https://www.omsaa.com/terms')}>Terms of Service</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => window.open('https://www.omsaa.com/privacy')}>Privacy Policy</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => window.open('https://www.omsaa.com/grievance')}>Grievance Policy</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => setShow(true)}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>

            {/* LOGOUT MODAL */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to logout ?</Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-25'>
                        <button type="submit" name="" value="procced" className="btns-cancel" onClick={() => handleClose()}>
                            No</button>
                        <button type="submit" name="" value="procced" className="btns ml-3" onClick={() => onLogout()}>
                            Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Header;