import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HelperService from "../../utility/HelperService";
import WebService from "../../utility/webService";
import moment from "moment";
import nextarrow from '../../assets/images/next-arrow.svg';
import backarrowimg from '../../assets/images/back-arrow.svg'
import Loader from "../../Components/Loader/Loader";
import DefaultImage from '../../assets/images/default.svg';

const ConsentList = () => {
    let location = useLocation();
    let navigate = useNavigate();
    const [consentList, setConsentList] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getConsentList()
    }, [])

    const getConsentList = () => {
        setLoading(true)
        WebService.getAPI({
            action: `/aaclient/Consent/list/${location.state && location.state.type}`,
            body: null
        })
            .then((res: any) => {
                for (var i in res.list) {
                    res.list[i].consentArtifact = JSON.parse(res.list[i].consentArtifact)
                }
                setConsentList(res.list)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }
    return (
        <>
            <Loader show={isLoading} />
            <section className="mt100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a onClick={() => window.history.back()} className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>{location.state && HelperService.getTitleCase(location.state.type)}</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <h4 className="d-none d-sm-block mt-4">{location.state && HelperService.getTitleCase(location.state.type)}</h4>
                            {
                                consentList.length > 0 && consentList.map((res, index) => {
                                    return (
                                        <div className="pt-4 pointer" onClick={() => navigate(`/consent/${location.state && HelperService.getTitleCase(location.state.type)}`, { state: { data: res } })} key={index}>
                                            <div className="pb-4"><img src={res.logoUrl ? res.logoUrl : DefaultImage} alt="" className="pull-left mr-2 mt-1" width="36" height="36" /> {res.title}<br /><small className="day-ago">{moment(res.createdDt).format('DD/MM/YYYY hh:mm A')}</small> <a className="next-arrow"><img src={nextarrow} alt="Next" /></a></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ConsentList;