import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WebService from "../../utility/webService";
import Modal from 'react-bootstrap/Modal';
import { setDataInRedux, USER_LOGOUT } from "../../action/CommonActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import backarrowimg from '../../assets/images/back-arrow.svg'

const Editprofile = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [userDetail, setUserDetail] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    let navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();

    const handleClose = () => setShow(false);

    useEffect(() => {
        getUserDetail()
    }, [])

    const getUserDetail = () => {
        WebService.getAPI({
            action: `/aaclient/customer`,
            body: null
        })
            .then((res: any) => {
                setUserDetail(res)
                setName(res.name)
                setEmail(res.email)
            })
            .catch((e) => {

            })
    }

    const updateUser = () => {
        setLoading(true)
        WebService.putAPI({
            action: `/aaclient/customer`,
            body: {
                name: name,
                email: email
            }
        })
            .then((res: any) => {
                setLoading(false)
                toast.success(res.message)
            })
            .catch((e) => {
                setLoading(false)
                toast.error(e?.response?.data?.errorMsg)
            })
    }

    const onDeleteAccount = () => {
        WebService.deleteAPI({
            action: `/aaclient/customer`,
            body: null
        })
            .then((res: any) => {
                sessionStorage.clear()
                localStorage.clear();
                dispatch(setDataInRedux({ type: USER_LOGOUT, value: '' }));
                navigate('/login')
            })
            .catch((e) => {
                toast.error(e.response.data.errorMsg)
            })
    }

    return (
        <>
            <section className="mt100 Omeditprofile-wrap">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-sm-12">
                            <div className="white-bg d-block d-sm-none">
                                <a onClick={() => window.history.back()} className="back-arrow"><img src={backarrowimg} alt="Back" /></a>
                                <span>Edit Details</span>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 mx-auto my-auto">
                            <h4 className="d-none d-sm-block mt-4">Edit Details</h4>
                            <div className="border-btm">
                                <div className="con-title">Full Name</div>
                                <input className="dates" type="text" placeholder="John Deo" value={name} onChange={(e) => setName(e.target.value)} />
                                {/* <div className="dates">John Deo</div> */}
                            </div>
                            <div className="border-btm">
                                <div className="con-title">OMS ID</div>
                                <div className="dates">{userDetail?.vpa}</div>
                                <div><small>You are not allowed to change your omsaa ID, once provide the consent or discovered account</small></div>
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Mobile Number</div>
                                <div className="dates">{userDetail?.mobile}</div>
                                <div><small>To change mobile number, you have to create a new ID</small></div>
                            </div>
                            <div className="border-btm">
                                <div className="con-title">Email ID</div>
                                <input className="dates" type="text" placeholder="omsmoney@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {/* <div className="dates">omsmoney@gmal.com</div> */}
                            </div>
                            <div className="form-group mt-5 btn-fixed">
                                <button type="button" name="" value="save" className="btns" disabled={isLoading} onClick={() => updateUser()}>
                                    {isLoading && <div className='om-btn-loader-m' style={{ marginLeft: '6vw' }}></div>}Save</button>
                            </div>
                            <div className="text-center py-1 pointer"><a className="tc" onClick={() => setShow(true)}>Delete Account</a></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* DELETE MODAL */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete account ?</Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-25'>
                        <button type="submit" name="" value="procced" className="btns-cancel" onClick={() => handleClose()}>
                            No</button>
                        <button type="submit" name="" value="procced" className="btns ml-3" onClick={() => onDeleteAccount()}>
                            Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )


}

export default Editprofile;