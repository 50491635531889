import menuimg from '../../assets/images/account-menu.svg'
import homemenuimg from '../../assets/images/home-menu.svg'
import consentimg from '../../assets/images/consent-menu.svg'

const Footer = () => {
    return (
        <>
            <div className="bottom-spacer">&nbsp;</div>
            <footer className="mobile-ft-menu">
                <div className="container-fluid h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-4 text-center my-auto">
                            <a href="/manage-accounts"><img src={menuimg} alt="Accounts" /><br />
                                Account
                            </a>
                        </div>
                        <div className="col-4 text-center my-auto">
                            <a href="/home"><img src={homemenuimg} alt="Home" /></a>
                        </div>
                        <div className="col-4 text-center my-auto">
                            <a href="/manage-consent"><img src={consentimg} alt="Consent" /><br />
                                Consent
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;