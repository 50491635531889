import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import logo from '../../assets/images/logo.svg';
import axios from 'axios';
import { BASE_URL } from '../../utility/webService';
import { useNavigate, useLocation } from "react-router-dom";
import { USER_LOGIN_SUCCESS, USER_LOGOUT, setDataInRedux } from '../../action/CommonActions';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import OTPInput from "react-otp-input";
import Modal from 'react-bootstrap/Modal';
import Loader from '../../Components/Loader/Loader';


const VerifyPin = () => {
    const [counter, setCounter] = useState(59);
    let history = useNavigate();
    let navigate = useNavigate();
    let location = useLocation();
    const [isLoading, setLoading] = useState(false)
    const dispatch: Dispatch<any> = useDispatch();
    const [pin, setPin] = useState('')
    const pinRef = useRef('');
    const otpRef = useRef<any>();
    const [show, setShow] = useState(false);
    const [isResendLoader, setResendLoader] = useState(false);

    useEffect(() => {
        if (location.state == null) {
            history('/login')
        }
    }, [location]);

    setTimeout(() => {
        var count: any = counter - 1;
        if (count >= 0) {
            count = count > 9 ? count : '0' + '' + count;
        }
        if (count >= 0) {
            setCounter(count)
        } else if (count == 0) {
            return
        }
    }, 1000);

    const handleChange = (value: any) => {
        setPin(value)
        pinRef.current = value
        if (value.length == 6) {
            document.getElementById('verify')?.click()
        }
    }


    const onVerify = () => {
        if (pinRef.current.length < 6) {
            toast.error('Please enter correct otp')
        } else {
            setLoading(true)
            const headers = {
                'Content-Type': 'application/json',
                'appLang': 'en_US'
            }
            axios.post(`${BASE_URL}/aaclient/pin/verify `, {
                mobile: location.state.mobile,
                pin: pinRef.current
            }, {
                headers: headers
            })
                .then((res) => {
                    setLoading(false)
                    if (location.state.page == 'LOGIN') {
                        dispatch({
                            type: USER_LOGIN_SUCCESS,
                            payload: { access_token: res.data.token },
                        });
                        sessionStorage.setItem("token", res.data.token);
                        history("/home");
                    } else {
                        history("/success", { state: { id: res.data.vpa, token: res.data.token } });
                    }
                })
                .catch((e) => {
                    setPin('')
                    otpRef.current.focusInput(0)
                    setLoading(false)
                    toast.error(e.response.data.errorMsg)
                    if (e.response.data.block) {
                        sessionStorage.clear()
                        localStorage.clear();
                        dispatch(setDataInRedux({ type: USER_LOGOUT, value: '' }));
                        navigate('/login')
                    }
                })
        }
    }

    const onResend = () => {
        setShow(false)
        setResendLoader(true)
        const headers = {
            'Content-Type': 'application/json',
            'appLang': 'en_US'
        }
        axios.post(`${BASE_URL}/aaclient/customer/pin/forgot`, {
            mobile: location.state.mobile,
        }, {
            headers: headers
        })
            .then((res: any) => {
                toast.success(res?.data?.message)
                setResendLoader(false)
            })
            .catch((e) => {
                toast.error(e.response.data.errorMsg)
                setResendLoader(false)
            })
    }


    return (
        <>
            <Loader show={isResendLoader} />
            <section className="vertical-center">
                <div className="container">
                    <div className="row align-items-center h-100">
                        <div className="col-lg-5 col-sm-5 mx-auto my-auto">
                            <div className="text-center my-5"><a ><img src={logo} alt="OMS AA" width="95" height="99" /></a>
                            </div>
                            <div className="tag-line pb-2">Verify your PIN</div>
                            <div className="sm-title pb-3">Enter PIN</div>
                            <form method="get" className="digit-group text-center mt-4" data-group-name="digits" data-autosubmit="false">
                                <div className="otp">
                                    <OTPInput
                                        ref={otpRef}
                                        onChange={handleChange}
                                        value={pin}
                                        inputStyle="otpInputStyle"
                                        className='otpbox'
                                        isInputSecure={true}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        separator={<span></span>}
                                    />
                                </div>
                                {/* <div className="resend mt-3 ">Resend Activation Code <span>00:{counter}</span></div> */}
                                <div className="text-center mt-3"><a onClick={() => setShow(true)} className={'tc pointer'}>Forgot PIN</a></div>
                                <div className="form-group mt-5 btn-fixed">
                                    <button type="button" name="" value="Verify" className="btns" id="verify" onClick={() => onVerify()} disabled={isLoading}>
                                        {isLoading && <div className='om-btn-loader-m'></div>}Verify</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header >
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to resend PIN on your registered email / mobile no. ?</Modal.Body>
                <Modal.Footer>
                    <div className='d-flex w-25'>
                        <button type="submit" name="" value="procced" className="btns-cancel" onClick={() => setShow(false)}>
                            No</button>
                        <button type="submit" name="" value="procced" className="btns ml-3" onClick={() => onResend()}>
                            Yes</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default VerifyPin;